import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import React from "react";
import HomePage from './pages/Homepage';
import RootLayout from './pages/Root';
import JournalPage from './pages/JournalPage';
import AddressPage from './pages/AddressPage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import PressPage from './pages/PressPage';
import AddressDetailPage from './pages/AddressDetailPage';
import Login from './pages/Login';



const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
    id: 'login',
  },
  {
      path: '/',
      element: <RootLayout />,
      id:'root',
      children: [
        
          {
            index: true,
            id:'home',
            element: <HomePage />,
          },
          {
            path:'addresses',
            id:'addresses',
            children: [
              {
                index: true,
                id:'address',
                element: <AddressPage />,
              },
              {
                path: ':index',
                id:'address-detail',
                element: <AddressDetailPage />,
              },
            ]
          },
          {
            path:'journal',
            id:'journal',
            element: <JournalPage />,
          },
          {
            path:'contact',
            id:'cnotact',
            element: <ContactPage />,
          },
          {
            path:'about',
            id:'about',
            element: <AboutPage />,
          },
          {
            path:'press',
            id:'press',
            element: <PressPage />,
          },
         
      ]
    },
  ]);

  function App() {
    return <RouterProvider router={router} />
  }
  
export default App;
