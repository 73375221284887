import React, {useEffect, useState} from "react";
import { styles } from '../../styles';
import { useTranslation } from 'react-i18next';




const Bedrooms = ({ data }) => {

    const { t } = useTranslation();
    const [screenWidth, setScreenWidth] = useState(0);



    useEffect(() => {

        setScreenWidth(window.innerWidth);
        window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
        return () =>
            window.removeEventListener("resize", () =>
                setScreenWidth(window.innerWidth)
            );

    }, []);

    return (
        <>
            {data.bedrooms && data.bedrooms.length > 0 ? (
                <div className="py-10">
                    <div className={`${styles.paddingX} flex flex-col items-center justify-center pb-5`}>
                        <h2 className={`${styles.secondaryTextColor} ${styles.secondSectionTitle} inter-bold text-center pb-5`}>
                            {t('detailocation.title.bedroom')}
                        </h2>
                        <p className={`${styles.thirdTextColor} ${styles.regularText}  libre-baskerville-regular max-w-[700px] text-center`}>
                            {data.description_bedroom}
                        </p>
                    </div>
                    {data.bedrooms.length > 4 && screenWidth > 400 ? (
                        <div className="flex overflow-x-auto hide-scrollbar">
                            <div className='flex flex-nowrap gap-3 ml-10 mr-10 justify-center'>
                                {data.bedrooms && data.bedrooms.map((bed, index) => (
                                    <div key={index} className=" flex flex-col max-w-[330px] w-[330px] mt-5 sm:mt-0">
                                        <img src={bed.image} alt="loc pic" className='h-[400px] w-[330px] object-cover' />
                                        <div className="pt-4">
                                            <p className={`${styles.secondaryTextColor} ${styles.locationTitle} inter-bold text-start`}>{bed.name}</p>
                                            <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start libre-baskerville-regular-italic pt-2`}>{bed.subname}</p>
                                            <div className="flex pt-2 flex-wrap max-w-[350px]">
                                                <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mr-2`}>{bed.surface} {t('location.nb.level.m2')} {bed.level} {bed.level === 1 ? t('location.nb.level.1') : t('location.nb.level.2')}</p>
                                                <span className={`border-l border-[#5A5C20]`} />
                                                {bed.king > 0 && (
                                                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mx-1`}>{bed.king} {bed.king === 1 ? t('location.nb.king.1') : t('location.nb.king.2')}</p>
                                                )}
                                                {bed.queen > 0 && (
                                                    <>
                                                        <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin`}>+</p>
                                                        <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mx-1`}>{bed.queen} {bed.queen === 1 ? t('location.nb.queen.1') : t('location.nb.queen.2')}</p>
                                                    </>
                                                )}
                                                {bed.solo > 0 && (
                                                    <>
                                                        <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin`}>+</p>
                                                        <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mx-1`}>{bed.solo} {bed.solo === 1 ? t('location.nb.solo.1') : t('location.nb.solo.2')}</p>
                                                    </>
                                                )}
                                            </div>
                                            <div className='flex pt-2'>
                                                {bed.equipments && bed.equipments.slice(0, 2).map((equipement, index) => (
                                                    <React.Fragment key={index}>
                                                        <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin`}>{equipement.name}</p>
                                                        {index !== 1 && <span className={`border-l border-[#5A5C20] mx-2`} />}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (

                        <div className='flex flex-wrap gap-3 justify-center'>
                            {data.bedrooms && data.bedrooms.map((bed, index) => (
                                <div key={index} className=" flex flex-col max-w-[330px] w-[330px] mt-5 sm:mt-0">
                                    <img src={bed.image} alt="loc pic" className='h-[400px] w-[330px] object-cover' />
                                    <div className="pt-4">
                                        <p className={`${styles.secondaryTextColor} ${styles.locationTitle} inter-bold text-start`}>{bed.name}</p>
                                        <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start libre-baskerville-regular-italic pt-2`}>{bed.subname}</p>
                                        <div className="flex pt-2 flex-wrap max-w-[350px]">
                                            <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mr-2`}>{bed.surface} {t('location.nb.level.m2')} {bed.level} {bed.level === 1 ? t('location.nb.level.1') : t('location.nb.level.2')}</p>
                                            <span className={`border-l border-[#5A5C20]`} />
                                            {bed.king > 0 && (
                                                <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mx-1`}>{bed.king} {bed.king === 1 ? t('location.nb.king.1') : t('location.nb.king.2')}</p>
                                            )}
                                            {bed.queen > 0 && (
                                                <>
                                                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin`}>+</p>
                                                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mx-1`}>{bed.queen} {bed.queen === 1 ? t('location.nb.queen.1') : t('location.nb.queen.2')}</p>
                                                </>
                                            )}
                                            {bed.solo > 0 && (
                                                <>
                                                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin`}>+</p>
                                                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin mx-1`}>{bed.solo} {bed.solo === 1 ? t('location.nb.solo.1') : t('location.nb.solo.2')}</p>
                                                </>
                                            )}
                                        </div>
                                        <div className='flex pt-2'>
                                            {bed.equipments && bed.equipments.slice(0, 2).map((equipement, index) => (
                                                <React.Fragment key={index}>
                                                    <p className={`${styles.secondaryTextColor} ${styles.locationSubTitle} text-start inter-thin`}>{equipement.name}</p>
                                                    {index !== 1 && <span className={`border-l border-[#5A5C20] mx-2`} />}
                                                </React.Fragment>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    )}

                </div>
            ) : null}
        </>
    );
}

export default Bedrooms