
import '../App.css';
import React, { useEffect, useState } from "react";
import { backAddresses, maps } from '../assets';
import { styles } from "../styles";
import { useTranslation } from 'react-i18next';
import SearchBar from '../components/SearchBar/SearchBar';
import { useLocation } from 'react-router-dom';
import Filter from '../components/Filter/Filter';
import LocationCard from '../components/UI/LocationCard';
import axiosInstance from '../context/axiosConfig';
import {
    useNavigate,
} from 'react-router-dom';

function AddressPage() {
    const { t } = useTranslation();
    const { state } = useLocation();
    const { event, traveler, destination } = state || {};
    const toUpperCase = (text) => {
        return text.toUpperCase();
    };
    const navigate = useNavigate();
    const [newEvent, setNewEvent] = useState(null);
    const [newTraveler, setNewTraveler] = useState(null);
    const [newDestination, setNewDestination] = useState(null);
    const [screenWidth, setScreenWidth] = useState(0);
    const [isListView, setIsListView] = useState(true);
    const [filterData, setFilterData] = useState([]);
    const [locationData, setLocationData] = useState([]);


    function searchFilter() {
        //console.log('Send Filter to back   : ', newDestination, newTraveler, newEvent)
        //envoie d'une demande au back de fitrage de résultat
    }

    function handleUpdate(dest, trav, even) {
        setNewDestination(dest);
        setNewEvent(trav);
        setNewTraveler(even);
    }

    function goToHandler(data, index) {
        //console.log('Nav props : ', data, index);
        navigate(`/addresses/${data.id}`, { state: { data, index } });
        window.scrollTo(0, 0);
    }

    {/*useEffect(() => {
        console.log('Search filter : ', newDestination, newTraveler, newEvent);
    }, [newDestination, newTraveler, newEvent])*/}


    useEffect(() => {

        setScreenWidth(window.innerWidth);
        window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
        return () =>
            window.removeEventListener("resize", () =>
                setScreenWidth(window.innerWidth)
            );

    }, []);

    useEffect(() => {
        axiosInstance.get('/api/filter/')

          .then(res => {
            setFilterData(res.data);
            //console.log('response data:', res.data);
          })
          .catch(err => {
            //console.error('Error fetching filter data:', err);
          });
    }, []);

    useEffect(() => {
        axiosInstance.get('/api/addresses/')
            .then(res => {
                setLocationData(res.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, []);


    return (
        <>
            <div className="App flex-col">
                <div className="relative h-[700px] w-full">
                    <img src={backAddresses} alt="back" className='absolute top-0 left-0 h-[700px] w-full object-cover' />
                    <div className={`flex relative h-full w-full items-end justify-end`}>
                        <h2 className={`${styles.primaryTextColor} ${styles.sectionTitle}  inter-bold m-10 max-w-[500px] text-center md:text-start`}>
                            {toUpperCase(t('address.title'))}
                        </h2>
                    </div>
                </div>
                <div className={`${styles.padding} flex max-w-8xl flex-col justify-center mx-auto items-center`}>
                    <div className='sm:mx-auto mx-0'>
                        <SearchBar mode={'secondary'} filter={filterData} onClick={searchFilter} onUpdate={(d, e, t) => handleUpdate(d, e, t)} event={event} traveler={traveler} destination={destination} />
                    </div>
                    <Filter secondFilter={filterData} />
                </div>

                <div className='mb-20 mx-auto max-w-8xl flex flex-col justify-center mx-0 md:mx-[20px]'>
                    <p className={`${styles.thirdTextColor} ${styles.sectionSubTitle}  text-center md:text-start libre-baskerville-regular pb-0 md:pb-10 sm:mx-[30px] md:mx-[0px]`}>{t('resultsearch.nbresult.1')}
                        <span className='libre-baskerville-regular-italic mx-2 '>{locationData.length} {t('resultsearch.nbresult.2')}</span>
                        {t('resultsearch.nbresult.3')}
                    </p>
                    {screenWidth > 970 ? (
                        <div className='flex flex-col md:flex-row justify-center '>
                            <div className='w-full flex flex-wrap md:w-2/3 justify-center'>
                                <LocationCard location={locationData} onClick={goToHandler} />
                            </div>
                            <div className='pt-5 md:pt-0 w-full flex justify-center md:w-1/3'>
                                <img src={maps} alt="maps-img" className='h-full' />
                            </div>
                        </div>
                    ) : (
                        <div className='flex flex-col justify-center items-center'>
                            <div className='border-[1px] border-[#5A5C20] m-10 flex rounded-full'>
                                <div onClick={() => setIsListView(!isListView)} className={`${isListView ? styles.secondaryBackColor : null} py-2 rounded-full cursor-pointer`}>
                                    <p className={`${styles.regularText} ${styles.secondaryTextColor} ${isListView ? styles.primaryTextColor : null} px-3 inter-thin`}>{t('switch.view.list')}</p>
                                </div>
                                <div onClick={() => setIsListView(!isListView)} className={`${!isListView ? styles.secondaryBackColor : null} py-2 rounded-full cursor-pointer`}>
                                    <p className={`${styles.regularText} ${styles.secondaryTextColor} ${!isListView ? styles.primaryTextColor : null} px-3 inter-thin`}>{t('switch.view.map')}</p>
                                </div>
                            </div>
                            {isListView ? (
                                <div className='w-full flex md:gap-2 gap-0 flex-wrap justify-center'>
                                    <LocationCard location={locationData} onClick={goToHandler} />
                                </div>
                            ) : (
                                <div className=' w-full flex justify-center'>
                                    <img src={maps} alt="maps-img" className='h-full' />
                                </div>
                            )}

                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default AddressPage;
